


























import menuStore from '@/store/Menu'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  mounted(): void {
    menuStore.setInvisible()
  }

  move(): void {
    this.$router.push({
      name: 'Login',
    })
  }
}
